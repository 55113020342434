import React, { useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Orders = (props) => {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("des");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [url, setUrl] = useState("/api/v1/admin/orders/read");
  const [search, setSearch] = useState("");

  useEffect(() => {
    getOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, filter, limit, search]);

  const getOrders = async () => {
    var link =
      url +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&sort=" +
      sort +
      "&status=" +
      filter +
      "&search=" +
      search;

    await axios
      .get(server + link, config)
      .then((res) => {
        setOrders(res.data.orders);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const refundOrder = async (id) => {
    await axios
      .put(server + "/api/v1/admin/orders/refund/" + id, {}, config)
      .then((res) => {
        toast.success(res.data.message);
        getOrders();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Orders</h3>
                  </div>
                </div>

                {/*  */}

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-4">
                          <div className="d-md-flex justify-content-between align-items-center">
                            <div className="d-md-flex justify-content-center align-items-center ">
                              <div className="form-group ml-2 mt-3">
                                <select
                                  name="sort"
                                  id=""
                                  className="form-control"
                                  value={sort}
                                  onChange={(e) => {
                                    setSort(e.target.value);
                                  }}
                                >
                                  <option value="asc">
                                    Sort by Date Ascending
                                  </option>
                                  <option value="des">
                                    Sort by Date Descending
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="d-md-flex justify-content-center align-items-center">
                              {/* .search */}
                              <div className="col mt-3">
                                <div className="form-group mr-3 ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={search}
                                    onChange={(e) => {
                                      setSearch(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">User</th>
                                  <th scope="col">Total</th>
                                  <th scope="col">LabelType</th>
                                  <th scope="col">Tracking</th>
                                  <th scope="col">Created At</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.map((order, index) => (
                                  <tr key={order._id}>
                                    <td>{index + 1 + (page - 1) * limit}</td>
                                    <td>{order.user?.username}</td>
                                    <td>${order.price}</td>
                                    <td>{order.labelType?.name}</td>
                                    <td>{order.tracking}</td>

                                    <td>
                                      {new Date(order.createdAt).toString()}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-sm btn-primary mr-2"
                                        onClick={() => {
                                          setOrder(order);
                                        }}
                                        data-toggle="modal"
                                        data-target="#view"
                                      >
                                        <em className="icon ni ni-eye"></em>
                                      </button>
                                      {!order.isRefunded && (
                                        <button
                                          className="btn btn-sm btn-info"
                                          onClick={() => {
                                            refundOrder(order._id);
                                          }}
                                        >
                                          Refund Order
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="view"
        className="modal fade "
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                View Order
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title text-center">From</h5>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <span className="fw-bold">Name:</span> {order.fromName}{" "}
                        <br />
                        <span className="fw-bold">Company:</span>{" "}
                        {order.fromCompany} <br />
                        <span className="fw-bold">Street:</span>{" "}
                        {order.fromStreet} <br />
                        <span className="fw-bold">Street2:</span>{" "}
                        {order.fromStreet2} <br />
                        <span className="fw-bold">City:</span> {order.fromCity}{" "}
                        <br />
                        <span className="fw-bold">State:</span>{" "}
                        {order.fromState} <br />
                        <span className="fw-bold">Zip:</span> {order.fromZip}{" "}
                        <br />
                        <span className="fw-bold">Country:</span>{" "}
                        {order.fromCountry} <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title text-center">To</h5>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <span className="fw-bold">Name:</span> {order.toName}{" "}
                        <br />
                        <span className="fw-bold">Company:</span>{" "}
                        {order.toCompany} <br />
                        <span className="fw-bold">Street:</span>{" "}
                        {order.toStreet} <br />
                        <span className="fw-bold">Street2:</span>{" "}
                        {order.toStreet2} <br />
                        <span className="fw-bold">City:</span> {order.toCity}{" "}
                        <br />
                        <span className="fw-bold">State:</span> {order.toState}{" "}
                        <br />
                        <span className="fw-bold">Zip:</span> {order.toZip}{" "}
                        <br />
                        <span className="fw-bold">Country:</span>{" "}
                        {order.toCountry} <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title text-center">
                        Additional details
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <span className="fw-bold">Label Type:</span>{" "}
                        {order.labelType?.name} <br />
                        <span className="fw-bold">Weight:</span> {order.weight}
                        {order.labelType?.name.includes("First Class") ? (
                          <span className="fw-bold">oz</span>
                        ) : (
                          <span className="fw-bold">lb</span>
                        )}{" "}
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-end">
                <button className="btn btn-dark me-2" title="Download">
                  <i className="bi-download"></i>
                </button>
                <button className="btn btn-dark" title="Refund">
                  <i className="bi-currency-exchange"></i>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Orders;
