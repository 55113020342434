import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { server, config } from "../env";

const Invoices = (props) => {
  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("des");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [url, setUrl] = useState("/api/v1/admin/invoice/read");
  const [search, setSearch] = useState("");

  useEffect(() => {
    getInvoices();

    // eslint-disable-next-line
  }, [page, sort, filter, limit, search]);

  const getInvoices = async () => {
    var link = "";

    link =
      url +
      "?page=" +
      page +
      "&limit=" +
      limit +
      "&sort=" +
      sort +
      "&status=" +
      filter +
      "&search=" +
      search;

    await axios
      .get(server + link, config)
      .then((res) => {
        setInvoices(res.data.invoices);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const markasPaid = async (id) => {
    await axios
      .put(server + "/api/v1/admin/invoice/markaspaid/" + id, {}, config)
      .then((res) => {
        getInvoices();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Invoices</h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-4">
                          <div className="d-md-flex justify-content-between align-items-center">
                            <div className="d-md-flex justify-content-center align-items-center ">
                              <div className="form-group ml-2 mt-3">
                                <select
                                  name="sort"
                                  id=""
                                  className="form-control"
                                  value={sort}
                                  onChange={(e) => {
                                    setSort(e.target.value);
                                  }}
                                >
                                  <option value="asc">
                                    Sort by Date Ascending
                                  </option>
                                  <option value="des">
                                    Sort by Date Descending
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="d-md-flex justify-content-center align-items-center">
                              {/* .search */}
                              <div className="col mt-3">
                                <div className="form-group mr-3 ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={search}
                                    onChange={(e) => {
                                      setSearch(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Username</th>
                                  <th scope="col">Email</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Payment Method</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Created At</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoices.map((invoice, index) => (
                                  <tr key={invoice._id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{invoice.user.username}</td>
                                    <td>{invoice.user.email}</td>
                                    <td>{"$" + invoice.amount}</td>
                                    <td>{invoice.payment_method}</td>
                                    <td>
                                      {invoice.status === "pending" ? (
                                        <span className="badge badge-warning">
                                          {invoice.status}
                                        </span>
                                      ) : invoice.status === "paid" ? (
                                        <span className="badge badge-success">
                                          {invoice.status}
                                        </span>
                                      ) : (
                                        <span className="badge badge-danger">
                                          {invoice.status}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {new Date(
                                        invoice.createdAt
                                      ).toLocaleString()}
                                    </td>
                                    <td>
                                      <a
                                        href={invoice.hosted_url}
                                        className="btn btn-sm btn-info mr-2"
                                      >
                                        <em className="icon ni ni-eye"></em>
                                      </a>

                                      {invoice.status !== "paid" && (
                                        <button
                                          className="btn btn-sm btn-success"
                                          onClick={() => {
                                            markasPaid(invoice._id);
                                          }}
                                        >
                                          Mark as Paid
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
