import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { server, config } from "../env";
import Loader from "../components/Loader";
import Alert from "../components/Alert";

const USPSTracking = (props) => {
  const [trackings, setTrackings] = useState([]);
  const [stats, setStats] = useState();
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("des");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [url, setUrl] = useState("/api/v1/tracking/read");
  const [search, getTracking] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");

  const getLogs = async (search) => {
    var link = "";

    if (search) {
      link =
        url +
        "?page=" +
        page +
        "&limit=" +
        limit +
        "&sort=" +
        sort +
        "&search=" +
        search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
      // "&sort=" +
      // sort +
      // "&status=" +
      // filter;
    }

    await axios
      .get(server + link, config)
      .then((res) => {
        setTrackings(res.data.trackings);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.tracking(err);
      });
  };
  const getStats = async () => {
    var link = "/api/v1/tracking/stats";

    await axios
      .get(server + link, config)
      .then((res) => {
        setStats(res.data);
      })
      .catch((err) => {
        console.tracking(err);
      });
  };

  const addTracking = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const data = {
      amount: e.target.amount.value,
      type: e.target.type.value,
      trackRef: e.target.trackRef.value,
      template: e.target.template.value,
    };

    await axios
      .post(server + "/api/v1/tracking/create", data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getLogs();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const deleteTracking = async (id) => {
    await axios
      .delete(server + "/api/v1/tracking/delete/" + id, config)
      .then((res) => {
        getLogs();
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  useEffect(() => {
    getLogs(search);
    getStats();
  }, [page, sort, filter, limit, search]);

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      USPS Trackings
                    </h3>
                  </div>
                </div>
              </div>

              <h6 class="m-3">LEFT</h6>
              <div className="nk-block">
                <div className="col-12">
                  <ul className="row g-gs">
                    {stats &&
                      Object.entries(stats.left).map(([key, value]) => (
                        <li className="col-xxl-3 col-lg-4 col-sm-6" key={key}>
                          <div className="card card-bordered p-3 p-lg-4">
                            <h6 className="text-nowrap text-uppercase">
                              USPS {key}
                            </h6>
                            <div className="d-flex flex-row justify-content-between ">
                              <h3 className="text-primary">{value}</h3>
                              <em
                                style={{ fontSize: "2.5rem" }}
                                class="icon ni ni-bookmark-fill"
                              ></em>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <h6 class="m-3">USED</h6>
              <div className="nk-block">
                <div className="col-12">
                  <ul className="row g-gs">
                    {stats &&
                      Object.entries(stats.used).map(([key, value]) => (
                        <li className="col-xxl-3 col-lg-4 col-sm-6" key={key}>
                          <div className="card card-bordered p-3 p-lg-4">
                            <h6 className="text-nowrap text-uppercase">
                              USPS {key}
                            </h6>
                            <div className="d-flex flex-row justify-content-between ">
                              <h3 className="text-primary">{value}</h3>
                              <em
                                style={{ fontSize: "2.5rem" }}
                                class="icon ni ni-bookmark-fill"
                              ></em>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="nk-block">
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-lg-4 ">
                          <div className="d-flex flex-column flex-lg-row justify-content-between  align-items-center">
                            {/* .search */}
                            <div className="col-lg-4 col-12 mt-3">
                              <div className="form-group mr-3 ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  onChange={(e) => {
                                    getTracking(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-12 mt-3 d-flex justify-content-end">
                              <button
                                className="btn btn-primary"
                                data-toggle="modal"
                                data-target="#add"
                              >
                                Generate Tracking
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="card-filter px-4">
                          <div className="d-md-flex justify-content-between align-items-center">
                            {/* <div className="d-md-flex justify-content-center align-items-center ">
                              <div className="form-group ml-2 mt-3">
                                <select
                                  name="sort"
                                  id=""
                                  className="form-control"
                                  value={sort}
                                  onChange={(e) => {
                                    setSort(e.target.value);
                                  }}
                                >
                                  <option value="asc">
                                    Sort by Date Ascending
                                  </option>
                                  <option value="des">
                                    Sort by Date Descending
                                  </option>
                                </select>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Code</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Template</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {trackings.map((tracking) => (
                                  <tr key={tracking._id}>
                                    <td>{tracking.code}</td>
                                    <td>
                                      {tracking.used ? (
                                        <span className="badge badge-success">
                                          {"Used"}
                                        </span>
                                      ) : (
                                        <span className="badge badge-warning">
                                          {"Unused"}
                                        </span>
                                      )}
                                    </td>
                                    <td>{tracking.type}</td>
                                    <td>{tracking.template}</td>
                                    <td>
                                      {new Date(
                                        tracking.createdAt
                                      ).toLocaleString()}
                                    </td>

                                    <td>
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={(e) => {
                                          deleteTracking(tracking._id);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" tabIndex={-1} id="add">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create new Tracking</h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={addTracking} className="form-validate is-alter">
                <div className="form-group">
                  <label className="form-label" htmlFor="amount">
                    Amount
                  </label>

                  <input
                    type="number"
                    name="amount"
                    placeholder="Enter the amount"
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="type">
                    Type
                  </label>
                  <select name="type" id="" className="form-control">
                    <option value="" disabled="" hidden="">
                      select
                    </option>
                    <option value="priority">Priority</option>
                    <option value="express">Express</option>
                    <option value="ground_oz">Ground(OZ)</option>
                    <option value="ground_lbs">Ground(LBS)</option>
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="template">
                    Template
                  </label>
                  <select name="template" id="" className="form-control">
                    <option value="" disabled="" hidden="">
                      select
                    </option>
                    <option value="evs">EVS</option>
                    <option value="indica">Indicia</option>
                    <option value="pitney">Pitney Bowes</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="trackRef">
                    Track Ref
                  </label>

                  <input
                    type="text"
                    name="trackRef"
                    placeholder="Enter the tracking Reference"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <button className="btn btn-lg btn-primary" type="submit">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default USPSTracking;
