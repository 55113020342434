import React from "react";
import Header from "../components/Header";
import axios from "axios";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { server, config } from "../env";
import { useState, useEffect } from "react";

const Settings = (props) => {
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");

  const changePassword = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    if (e.target.newPassword.value !== e.target.password2.value) {
      setMessage(<Alert className="danger" message="Passwords do not match" />);
      setLoader("");
      return;
    }

    const data = {
      oldPassword: e.target.oldPassword.value,
      newPassword: e.target.newPassword.value,
    };

    await axios
      .post(server + "/admin/changePassword", data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
      e.target.reset();
    }, 3000);
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Admin Settings
                    </h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">Change Password</h5>
                      </div>

                      <div className="card-body">
                        {message}
                        <form onSubmit={changePassword}>
                          <div className="form-group">
                            <label htmlFor="oldPassword">Old Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="oldPassword"
                              placeholder="Enter old password"
                              name="oldPassword"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="newPassword">New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="newPassword"
                              placeholder="Enter new password"
                              name="newPassword"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="confirmPassword">
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="confirmPassword"
                              placeholder="Confirm new password"
                              name="password2"
                            />
                          </div>
                          <button type="submit" className="btn btn-primary">
                            Change Password {loader}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
